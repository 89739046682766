const date = Date.now();
(window as any).getRemoteEntryVersionQs = () => date;

if (location.pathname.includes('shared-report')) {
	import('./bootstrapNoauth');
} else {
	import('./apm').then(({ initApm }) => {
		const ap = initApm();

		ap.observe('transaction:end', function (transaction) {
			// if (transaction.type === 'user-interaction') {
			// try {
			// 	const classes = transaction.context.custom.classes ? '.' + transaction.context.custom.classes.split(' ').join('.') : '';
			// 	const el = document.querySelectorAll(classes);
			// 	if (el && el[0] && el[0].getAttribute('title')) {
			// 		transaction.name = transaction.name + ' - ' + el[0].textContent;
			// 	} else if (el && el[0] && el[0].getAttribute('placeholder')) {
			// 		transaction.name = transaction.name + ' - ' + el[0].getAttribute('placeholder');
			// 	}
			// 	transaction.name = transaction.name.length > 50 ? transaction.name.slice(0, 50) + '...' : transaction.name;
			// } catch (e) {}
			// }
			// console.log('apm', transaction);
		});

		import('./bootstrap');
	});
}
